import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import { CONTENT_STATUS } from "../../../../utils/constants";
import Counter from "../../Counter/Counter";
import { ContentType, Item, ItemInfo, Row } from "../ListComponents/ListComponents";
import JointDeliveryCheckbox from "./JointDelivery/JointDeliveryCheckbox";
import styles from "./Types.module.scss";

function Function({
  list,
  onDelete,
  onQuantityChange,
  readOnly,
  enableJointDelivery = false,
  onCheckJointDelivery,
  checkedJointDelivery,
  disableJointDelivery,
}) {
  const translate = useTranslate();

  const handleQuantityChange = async (newQuantity, item) => {
    if (newQuantity !== item.function.quantity) {
      const data = { function: { ...item.function, quantity: newQuantity } };
      onQuantityChange(item.id, data);
    }
  };

  return (
    <ContentType title={translate("CART_FUNCTION_TITLE")}>
      {list.map(item => (
        <Item key={item.id} orderData={item} onDelete={onDelete} readOnly={readOnly}>
          <ItemInfo>
            <div>
              <Row title={translate("FUNCTION")} text={item.function.value} />
              <Row title={translate("FUNCTION_TYPE")} text={item.function.type.value} />
              {enableJointDelivery && item.status.value != CONTENT_STATUS.DRAFT && (
                <JointDeliveryCheckbox
                  item={item}
                  onCheckJointDelivery={onCheckJointDelivery}
                  checkedJointDelivery={checkedJointDelivery}
                  disableJointDelivery={disableJointDelivery}
                />
              )}
              {item.deliveryDate && <Row title={translate("ETA")} text={item.deliveryDate} />}
            </div>
            {readOnly ? (
              <p className={styles.quantityText}>
                {item.function.quantity} {item.function.unit ? item.function.unit : translate("UNITS_FULL")}
              </p>
            ) : (
              <Counter
                onChange={quantity => handleQuantityChange(quantity, item)}
                min={1}
                startValue={item.function.quantity}
              />
            )}
          </ItemInfo>
        </Item>
      ))}
    </ContentType>
  );
}

Function.propTypes = {
  list: PropType.arrayOf(
    PropType.shape({
      contentType: PropType.string,
      function: PropType.object,
      functionAccessories: PropType.array,
      id: PropType.string,
      make: PropType.array,
      priority: PropType.object,
      status: PropType.number,
      subContentType: PropType.object,
      text: PropType.string,
    }),
  ),
  onDelete: PropType.func,
  onQuantityChange: PropType.func,
  readOnly: PropType.bool,
  enableJointDelivery: PropType.bool,
  onCheckJointDelivery: PropType.func,
  checkedJointDelivery: PropType.object,
  disableJointDelivery: PropType.bool,
};

export default Function;
