import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getClient(clientId) {
  const url = api.client.client(clientId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function findNavetClient(personalId, searchNavet = false) {
  const url = api.client.clients();
  if (searchNavet) url.addParameter("searchNavet", searchNavet);
  url.addParameter("newOrder", true);
  url.addParameter("identityNumber", personalId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getClients(filters = {}) {
  const url = api.client.clients();
  if (filters) setQueryFilters(filters, url);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateClient(clientId, data) {
  const url = api.client.client(clientId);
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getAddress(clientId, addressId) {
  const url = api.client.address({ clientId: clientId, addressId: addressId });
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function createAddress(clientId, data) {
  const url = api.client.address({ clientId });
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updateAddress(clientId, addressId, data) {
  const url = api.client.address({ clientId, addressId });
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function deleteAddress(clientId, addressId) {
  const url = api.client.address({ clientId, addressId });
  const request = new Request(url, Method.DELETE);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getContact(clientId, contactId, withPrescriber = true, prescriberId) {
  const url = api.client.contact({ clientId });
  if (contactId) url.addParameter("contactid", contactId);
  if (prescriberId) url.addParameter("prescriberId", prescriberId);
  if (withPrescriber) url.addParameter("includeCurrentUserAsPrescriber", withPrescriber);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function createContact(clientId, data) {
  const url = api.client.contact({ clientId });
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updateContact(clientId, contactId, data) {
  const url = api.client.contact({ clientId, contactId });
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function deleteContact(clientId, contactId) {
  const url = api.client.contact({ clientId, contactId });
  const request = new Request(url, Method.DELETE);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getHolding({ clientId, inventoryId, onlyInventory, filter }) {
  const url = api.client.holding({
    clientId,
    filter,
    inventoryId: inventoryId,
  });

  if (onlyInventory) url.addParameter("onlyinventory", true);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateHolding(clientId, inventoryId, data) {
  const url = api.client.holding({ clientId, inventoryId });
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function returnHoldingsToBaseStorage({ clientId, baseStorageId, data }) {
  const url = api.client.holding({ clientId });

  const request = new Request(url, Method.POST);
  return await sendRequest(
    request,
    {
      action: "RETURN_TO_STORAGE",
      payload: {
        holdings: data,
        toStorageId: baseStorageId,
      },
    },
    await defaultRestOptions(),
  );
}

async function scrapHoldingsFromClientHoldings({ clientId, data }) {
  const url = api.client.holding({ clientId });
  const request = new Request(url, Method.POST);
  return await sendRequest(
    request,
    {
      action: "SCRAP",
      payload: {
        ...data,
      },
    },
    await defaultRestOptions(),
  );
}

async function getTasks(id, filter) {
  const url = api.client.tasks(id);
  if (filter) setQueryFilters(filter, url);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getOrders(id, filter) {
  const url = api.client.orders(id);
  if (filter) setQueryFilters(filter, url);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function addAccessoriesToAid({ clientId, holdingId, data }) {
  const url = api.client.accessories({ clientId, holdingId });
  const request = new Request(url, Method.POST);

  return await sendRequest(
    request,
    {
      action: "ADD_FROM_HOLDING",
      payload: [...data],
    },
    await defaultRestOptions(),
  );
}

export default {
  getClient,
  findNavetClient,
  getClients,
  getAddress,
  getContact,
  updateClient,
  createAddress,
  updateAddress,
  deleteAddress,
  createContact,
  updateContact,
  deleteContact,
  getHolding,
  updateHolding,
  returnHoldingsToBaseStorage,
  scrapHoldingsFromClientHoldings,
  getTasks,
  getOrders,
  addAccessoriesToAid,
};
