import { Method, Request, Response, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import i18n from "../../language/i18n";
import { clearAppData, setCompany, setFacility, setInformation, setOrder } from "../../reducers/appData/appDataActions";
import {
  resetSession,
  setAuthorizationList,
  setInitialized,
  setSessionId,
  setUserHasMessageToAccept,
} from "../../reducers/session/sessionAction";
import { getErrorMessage } from "../error/errorService";
import defaultRestOptions from "../utils/defaultRestOptions";

// POST /register
//TODO Is used?
async function register(newUser) {
  return await sendRequest(new Request(api.account.register()), newUser, await defaultRestOptions());
}

// POST /login
async function login(user) {
  const request = new Request(api.account.login(), Method.POST);
  const response = await sendRequest(request, user, await defaultRestOptions());

  if (response.isOk()) {
    setCompany({
      id: response.data.company.id,
      description: response.data.company.label,
    });

    setFacility({
      id: response.data?.facility?.id,
      description: response.data?.facility?.label,
      value: response.data?.facility?.value,
      category: response.data?.facility?.category,
    });

    setInformation(response.data.user);

    setOrder(response.data.order);

    // Session
    setSessionId(response.data.id);
    setAuthorizationList(response.data.authorizations);
    setUserHasMessageToAccept(response.data.accessMessage);

    setInitialized();
  }

  return response;
}

async function loginWithMylocToken(data) {
  const request = new Request(api.account.login(), Method.POST);
  //Don't send default options since it includes sessionId in header - if it exists from a previous session
  //Since we're at a login with Myloc token it is safe(?) to renew the session - or perhaps we should perform a logout first!?
  const response = await sendRequest(request, data);

  if (response.data?.id) {
    setSessionId(response.data.id);
  }

  return response;
}

async function getAppData() {
  const request = new Request(api.account.appData(), Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function logout(isSingleSignOn) {
  const request = new Request(api.account.logout(), Method.DELETE);
  const response = await sendRequest(request, null, await defaultRestOptions());

  if (response.isOk() || window.location.host === "localhost:3000") {
    resetSession();
    clearAppData();
    if (isSingleSignOn) {
      const domain = window.location.hostname;
      const redirectLocation =
        "/MyL/redirect_uri?logout=https%3A%2F%2F" + domain + encodeURIComponent("/MyL") + "%2Flogged-out.html";
      window.location = redirectLocation;
    } else {
      window.location = "/";
    }
  }

  return response;
}

async function updateAccountSettings(data) {
  const url = api.account.settings();
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updateStandardLocation(data) {
  const url = api.account.location();
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updatePassword(resetToken, data) {
  const url = api.account.password();
  const request = new Request(url, Method.PUT);
  let options = await defaultRestOptions();
  return await sendRequest(request, { ...data, token: resetToken }, options);
}

async function getPasswordPolicy() {
  const url = api.account.passwordRules();
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function resetPassword(data) {
  /**
   * Use a custom error handler to set a better descrption than sent from backend
   * The user sholud not see "not authorized" if user does not exist
   */
  const customErrorHandler = exception => {
    let status;
    let message;
    if (exception?.response?.status === 401) {
      //A non existing user has requested a password reset. Show standard message anyway together with status 200
      //Need to create message as object to distinguish from ordinary message when returned to page
      message = {
        text: i18n.t("RESET_LINK_IS_SENT"),
        isCustomHandled: true,
      };
      status = 200;
    }

    //If no custom message is set, use message from exception
    if (!message) {
      message = getErrorMessage(exception);
    }

    const responseStatus = status ? status : exception?.response?.status;
    return new Response(responseStatus, message, exception);
  };

  const url = api.account.reset();
  const request = new Request(url, Method.POST);

  return await sendRequest(request, data, await defaultRestOptions(customErrorHandler));
}

async function confirmAccessMessage(data) {
  const url = api.account.confirmAccessMessage();
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

export default {
  register,
  login,
  loginWithMylocToken,
  logout,
  getAppData,
  updateAccountSettings,
  updateStandardLocation,
  getPasswordPolicy,
  updatePassword,
  resetPassword,
  confirmAccessMessage,
};
