import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslate } from "../../language/i18n";
import orderService from "../../services/order/orderService";
import { AdditionalInfoSection, AddressSection, InfoSection, Row } from "../shared/OrderInfoSections/OrderInfoSections";
import styles from "./OrderInfo.module.scss";

function OrderInfo({ orderData }) {
  const translate = useTranslate();
  const [content, setContent] = useState({});
  const queryParams = useParams();

  useEffect(() => {
    orderService
      .getContent({ contentId: orderData.id || queryParams.content })
      .then(response => setContent(response.data));
  }, [queryParams.content, orderData.id]);

  const fittingInfo = content => (
    <>
      <InfoSection title={translate("FITTING_FUNCTION")}>
        <Row content={content?.fittingType?.name} />
      </InfoSection>
      <InfoSection title={translate("AID")}>
        <Row content={translate(content?.isNewItem ? "FITTING_OF_NEW_AID" : "FITTING_OF_EXISTING_AID")} />
        {content?.product?.inventoryNumber && (
          <Row title={translate("INVENTORY_NUMBER_SHORT")} content={content?.product?.inventoryNumber} />
        )}
        {content?.product?.productNumber && (
          <Row title={translate("PRODUCT_NUMBER_SHORT")} content={content?.product?.productNumber} />
        )}
        <Row title={translate("DESCRIBE_AID")} content={content?.isNewItem ? content?.product?.name : content?.text} />
      </InfoSection>
    </>
  );

  const functionInfo = data => (
    <InfoSection title={translate("FUNCTION")}>
      <Row title={data.label} content={data.value} />
      <Row title={data.type.label} content={data.type.value} />
    </InfoSection>
  );

  const showMake = data => (
    <InfoSection title={translate("CUSTOMIZATION")}>
      {data.map(item => (
        <Row key={item.id} title={item.label} content={item.value} />
      ))}
    </InfoSection>
  );

  const showAccessories = data => (
    <InfoSection title={translate("ACCESSORIES")}>
      {data.map(item => (
        <Row
          key={item.id}
          title={item.label}
          content={item.values.map(val => (
            <span key={val.id}>
              {val.value} {val.default && <span className={styles.default}>({translate("DEFAULT")})</span>}
            </span>
          ))}
        />
      ))}
    </InfoSection>
  );

  const NextMeeting = () => (
    <InfoSection title={translate("DESIRED_MEETING_TIME")}>
      <Row content={content?.nextMeeting.comment} />
    </InfoSection>
  );

  return (
    <section>
      {content && fittingInfo(content)}
      {content?.category && functionInfo(content.category)}
      {content?.make && showMake(content.make)}
      {content?.accessories && showAccessories(content.accessories)}
      {content?.form && <AdditionalInfoSection form={content.form} />}
      {(content?.address || content?.contact) && (
        <AddressSection address={content.address} contact={content.contact} title="EXECUTION_ADDRESS_AND_CONTACT" />
      )}
      {content?.nextMeeting && <NextMeeting />}
    </section>
  );
}

OrderInfo.propTypes = {
  orderData: PropTypes.object,
};

export default OrderInfo;
