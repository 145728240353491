import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { InputField } from "@myloc/myloc-gui";
import { useHistory } from "@myloc/myloc-utils";
import { useTranslate } from "../../language/i18n";
import baseStorageService from "../../services/baseStorage/baseStorageService";
import { CLIENT_TYPE } from "../../utils/constants";
import pages from "../../utils/pages";

import useDebounce from "../../hooks/useDebounce";
import FacilitySelector from "../shared/FacilitySelector/FacilitySelector";
import Page from "../shared/Page/Page";
import Paginate from "../shared/Pagination/Paginate";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import BaseStorageItem from "./BaseStorageItem";
import styles from "./BaseStoragesPage.module.scss";

const BaseStoragesPage = () => {
  const history = useHistory();
  const translate = useTranslate();
  const userFacility = useSelector(state => state.appData?.user?.facility);
  const orderReceiver = useSelector(state => state?.appData?.order?.receiver);
  const params = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);

  const getBaseStorage = () => {
    const paramStorage = params.get("storage");
    if (paramStorage) return { id: paramStorage };
    else if (userFacility?.category?.value > 0) return { id: userFacility?.id };
    else return null;
  };

  const [baseStorage, setBaseStorage] = useState(getBaseStorage());
  const [search, setSearch] = useState(params.get("q") || "");
  const debouncedSearchValue = useDebounce(search);
  const [stock, setStock] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(true);
  const [filters, setFilters] = useState({
    freeTextSearch: debouncedSearchValue,
    baseStorage: baseStorage?.id ? decodeURIComponent(baseStorage?.id) : null,
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!baseStorage?.id) {
      params.delete("storage");
    } else if (baseStorage?.id && params.get("storage") !== baseStorage.id) {
      params.set("storage", baseStorage.id);
    } else if (!params.get("storage")) {
      if (userFacility?.category?.value > 0) {
        params.set("storage", userFacility.id);
      }
    }

    if (!debouncedSearchValue) {
      params.delete("q");
    } else if (debouncedSearchValue !== params.get("q")) {
      params.set("q", debouncedSearchValue);
    }

    history.replace(pages.BASE_STORAGES.PATH, params);
  }, [userFacility, baseStorage, debouncedSearchValue, history]);

  const breadcrumbs = [
    {
      text: translate(pages.BASE_STORAGES.NAME),
    },
  ];

  const baseStorageId = baseStorage?.id;

  const fullSearch = useCallback(() => {
    setFilters({
      freeTextSearch: debouncedSearchValue,
      baseStorage: baseStorageId ? decodeURIComponent(baseStorageId) : null,
    });
  }, [debouncedSearchValue, baseStorageId]);

  useEffect(() => {
    fullSearch();
  }, [debouncedSearchValue, baseStorage, fullSearch]);

  const handleChange = useCallback(baseStorage => {
    if (baseStorage) {
      setBaseStorage(baseStorage);
    } else {
      setBaseStorage(null);
    }
  }, []);

  const sortedStock = stock?.length
    ? stock.sort((a, b) => {
        if (a?.product?.name > b?.product?.name) return 1;
        if (a?.product?.name < b?.product?.name) return -1;
        return 0;
      })
    : [];

  const getProvider = useCallback(
    async filter => {
      if (filters?.baseStorage !== null) {
        setShowErrorMessage(true);
        return baseStorageService.getBalance({
          ...filters,
          ...filter,
        });
      } else {
        setShowErrorMessage(false);
      }
    },
    [filters],
  );

  return (
    <Page title={translate("STOCK_BALANCE")} breadcrumbs={breadcrumbs}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setStock}
        provider={useCallback(filter => getProvider(filter), [getProvider])}
        errorMessage={{ show: showErrorMessage, message: "" }}
        loadOnMount={!!baseStorage?.id}
      >
        <section>
          <section className={styles.search}>
            <FacilitySelector preSelected={baseStorage} onSelect={handleChange} />
            <div className={styles.searchAndButtonWrapper}>
              <InputField
                label={translate("SEARCH_PRODUCT_NR_OR_NAME")}
                value={search}
                onChange={event => setSearch(event.target.value)}
                customCssClass={styles.noErrorField}
              />
            </div>
          </section>
        </section>
        {sortedStock.length > 0 && (
          <section className={styles.results}>
            {isLoading ? (
              <LoadingSpinner title="PRODUCTS_LOADING" />
            ) : (
              sortedStock.map(stockItem => (
                <BaseStorageItem
                  key={stockItem.id}
                  item={stockItem}
                  baseStorage={baseStorage}
                  hideLinks={
                    !baseStorage?.id ||
                    (orderReceiver?.type === CLIENT_TYPE.BASE_STORAGE && orderReceiver?.id !== baseStorage?.id)
                  }
                />
              ))
            )}
          </section>
        )}
      </Paginate>
    </Page>
  );
};

export default BaseStoragesPage;
