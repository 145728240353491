import { LoadingSpinner, Modal } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState, useCallback } from "react";
import { useTranslate } from "../../../language/i18n";
import productService from "../../../services/product/productService";

import Paginate from "../Pagination/Paginate";
import OrderHistoryRow from "./OrderHistoryRow";

import styles from "./OrderHistory.module.scss";

const OrderHistory = ({ visible, onClose, id }) => {
  const translate = useTranslate();
  const [orderHistory, setOrderHistory] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const productId = encodeURIComponent(id);

  return (
    <div className={styles.modal}>
      <Modal
        title={translate("ORDER_HISTORY")}
        visible={visible}
        onClose={onClose}
        showConfirmButton={false}
        showDeclineButton={false}
      >
        <Paginate
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          onChange={setOrderHistory}
          provider={useCallback(
            filter =>
              productService.getLogisticsOrdersForFixedAsset(productId, {
                ...filter,
                processCategory: "5:=;6:=;7:=",
              }),
            [productId],
          )}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            !!orderHistory?.length && orderHistory.map(history => <OrderHistoryRow key={history?.id} row={history} />)
          )}
        </Paginate>
      </Modal>
    </div>
  );
};

OrderHistory.propTypes = {
  visible: PropType.bool,
  onClose: PropType.func,
  id: PropType.string,
};

export default OrderHistory;
