import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import defaultRestOptions from "../utils/defaultRestOptions";
import { DEFAULT_QUERY_PARAM, QUERY_PARAM } from "../utils/pagination";

async function getProducts({
  from = DEFAULT_QUERY_PARAM.FROM,
  amount = DEFAULT_QUERY_PARAM.AMOUNT,
  searchValue = "",
  facilityIds = "",
  inStockAt = "",
  isoCode = "",
  supplier = "",
  itemClass = "",
  productStatus = "",
  sort = "",
  includeNotOrderable = false,
}) {
  const url = api.product.product();
  url.addParameter(QUERY_PARAM.FROM, from);
  url.addParameter(QUERY_PARAM.AMOUNT, amount);
  url.addParameter("searchValue", searchValue);
  url.addParameter("facilityIds", facilityIds);
  url.addParameter("inStockAt", inStockAt);
  url.addParameter("isoCode", isoCode);
  url.addParameter("supplier", supplier);
  url.addParameter("itemClass", itemClass);
  url.addParameter("productStatus", productStatus);
  url.addParameter("sort", sort);

  if (includeNotOrderable) url.addParameter("includeNotOrderable", includeNotOrderable);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getProduct(productId) {
  const url = api.product.productDetail({ productId });
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getProductsSuppliers(filters = {}) {
  const url = api.product.suppliers();
  if (filters) setQueryFilters(filters, url);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, null, await defaultRestOptions());
}

async function getPageContentsForProduct(productId, filters = {}) {
  const url = api.product.pageContents({ productId });
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getInventory(productId, facilityId) {
  const url = api.product.inventory();
  url.addParameter("productId", productId);
  url.addParameter("facilityid", facilityId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getFixedAsset(filter) {
  const url = api.product.fixedAsset({ productId: filter.product });
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}
async function getFixedAssetLabel(product) {
  const url = api.product.label({ productId: product });
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateFixedAsset(productId, data) {
  const url = api.product.fixedAsset({ productId });
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getLogisticsOrdersForFixedAsset(productId, filters = {}) {
  const url = api.product.logisticsOrder({ productId });
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getFilters(filters) {
  let defaultFilters = { facilities: true, baseStorages: true };
  const { facilities, baseStorages, includeNotOrderable } = { ...defaultFilters, ...filters };
  const url = api.product.filters();
  facilities && url.addParameter("facilities", true);
  baseStorages && url.addParameter("baseStorages", true);
  baseStorages && url.addParameter("isoCodes", true);
  baseStorages && url.addParameter("suppliers", true);
  includeNotOrderable && url.addParameter("includeNotOrderable", true);
  url.addParameter("itemClasses", true);
  url.addParameter("productStatuses", true);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getReasonCodes({ filters = {} }) {
  const url = api.product.reasonCodes();
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default {
  getProduct,
  getProducts,
  getProductsSuppliers,
  getPageContentsForProduct,
  getInventory,
  getFixedAsset,
  getFixedAssetLabel,
  updateFixedAsset,
  getLogisticsOrdersForFixedAsset,
  getFilters,
  getReasonCodes,
};
