import { useHistory } from "@myloc/myloc-utils";
import { useCallback, useEffect, useState } from "react";
import { useTranslate } from "../../language/i18n";
import pages from "../../utils/pages";
import Page from "../shared/Page/Page";

import useDebounce from "../../hooks/useDebounce";
import productService from "../../services/product/productService";
import Paginate from "../shared/Pagination/Paginate";
import ProductCatalogue from "./ProductCatalogue/ProductCatalogue";
import ProductFilter from "./ProductFilter/ProductFilter";

export const VIEWS = {
  GRID: "grid",
  LIST: "list",
};

const productFilters = [
  { id: "facilities", title: "MUNICIPALITY" },
  { id: "baseStorages", title: "BASE_STORAGE_AVAILABILITY" },
  { id: "itemClasses", title: "ITEM_CLASS" },
  { id: "isoCodes", title: "ISO_CODE" },
  { id: "suppliers", title: "SUPPLIER" },
  { id: "productStatuses", title: "PRODUCT_STATUS" },
  { id: "includeNotOrderable", title: "NOT_ORDERABLE", include: false },
];

// Converts encoded product filter params to usable filters
const getProductFiltersFromParams = params => {
  const reducedFilters = productFilters.reduce(
    (filters, val) => ({
      ...filters,
      [val.id]: {
        id: decodeFilterSearchParam(params.get(val.include)) || decodeFilterSearchParam(params.get(val.id)) || [],
        title: val.title,
        include: decodeFilterSearchParam(params.get(val.id[0])) || val.include,
      },
    }),
    {},
  );

  if (
    reducedFilters.includeNotOrderable?.id?.length &&
    JSON.parse(reducedFilters.includeNotOrderable?.id[0]) !== reducedFilters.includeNotOrderable?.include
  ) {
    reducedFilters.includeNotOrderable.include = JSON.parse(reducedFilters.includeNotOrderable?.id[0]);
  }
  return reducedFilters;
};

// Encodes array of filters to string separated by (encoded) commas
// ["HUL¤102"] -> "HUL%C2%A4102"
const encodeFilterSearchParam = values => {
  return encodeURIComponent(values.id.map(val => `${val}`).join(","));
};

// Decodes
// "HUL%C2%A4102" -> "["HUL¤102"]"
const decodeFilterSearchParam = param => {
  if (!param) return null;
  return param.split(encodeURIComponent(",")).map(p => decodeURIComponent(p));
};

function ProductsPage() {
  const history = useHistory();
  const translate = useTranslate();
  const params = new URLSearchParams(window.location.search);
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [productFilters, setProductFilters] = useState(getProductFiltersFromParams(params));
  const [searchValue, setSearchValue] = useState(params.get("q") || "");
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const productFiltersChanged = (params, filters) => {
      if (!filters) return false;
      return Object.entries(filters).some(([key, value]) => params.get(key) != encodeFilterSearchParam(value));
    };

    if (!searchValue) {
      params.delete("q");
    } else if (searchValue !== params.get("q")) {
      params.set("q", searchValue);
    }

    if (productFiltersChanged(params, productFilters)) {
      Object.entries(productFilters).forEach(([key, value]) => {
        if (key === "includeNotOrderable") {
          value.include ? params.set(key, value.include) : params.delete(key);
        } else {
          value.id?.length ? params.set(key, encodeFilterSearchParam(value)) : params.delete(key);
        }
      });
    }

    history.replace(pages.PRODUCTS.PATH, params);
  }, [productFilters, searchValue, history]);

  const setParams = (param, newValue) => {
    const oldValue = params.get(param);

    if (!oldValue) {
      params.set(param, VIEWS.GRID);
      history.replace(pages.PRODUCTS.PATH, params);
      return VIEWS.GRID;
    } else {
      if (oldValue === newValue) return null;
      else if (oldValue && !newValue) {
        return oldValue;
      }
      if (newValue) {
        params.set(param, newValue);
        history.replace(pages.PRODUCTS.PATH, params);
        return newValue;
      } else return VIEWS.GRID;
    }
  };

  return (
    <Page title={translate(pages.PRODUCTS.NAME)}>
      <Paginate
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onChange={setData}
        provider={useCallback(
          filter =>
            productService.getProducts({
              facilityIds: productFilters?.facilities.id.map(facility => decodeURIComponent(facility)),
              inStockAt: productFilters?.baseStorages.id.map(storage => decodeURIComponent(storage)),
              isoCode: productFilters?.isoCodes?.id.map(iso => decodeURIComponent(iso))?.[0],
              supplier: productFilters?.suppliers?.id.map(supplier => decodeURIComponent(supplier))?.[0],
              itemClass: productFilters?.itemClasses?.id.map(itemClass => decodeURIComponent(itemClass))?.[0],
              productStatus: productFilters?.productStatuses?.id.map(status => decodeURIComponent(status))?.[0],
              includeNotOrderable: productFilters?.includeNotOrderable?.include,
              searchValue: debouncedSearchValue,
              sort: "description:asc",
              ...filter,
            }),
          [debouncedSearchValue, productFilters],
        )}
      >
        <ProductFilter
          showFilter={showFilter}
          hideFilter={() => setShowFilter(false)}
          productFilters={productFilters}
          setProductFilters={setProductFilters}
        >
          <ProductCatalogue
            data={data}
            productFilters={productFilters}
            setProductFilters={setProductFilters}
            showFilter={useCallback(() => setShowFilter(true), [setShowFilter])}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isLoading={isLoading}
            setParams={setParams}
          />
        </ProductFilter>
      </Paginate>
    </Page>
  );
}

export default ProductsPage;
