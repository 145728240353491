import PropType from "prop-types";

import { Checkbox } from "@myloc/myloc-gui";
import { useState } from "react";
import { useTranslate } from "../../../../../language/i18n";

const JOINT_DELIVERY_ENABLED = 1;
const JOINT_DELIVERY_DISABLED = 0;

const JointDeliveryCheckbox = ({ item, checkedJointDelivery, onCheckJointDelivery, disableJointDelivery }) => {
  const translate = useTranslate();

  const [checked, setChecked] = useState(item.codeliveryId ? true : false);

  const onChange = item => {
    setChecked(checked => !checked);
    onCheckJointDelivery({
      ...item,
      codeliveryId: !checked ? JOINT_DELIVERY_ENABLED : JOINT_DELIVERY_DISABLED,
    });
  };

  const isDisabled = () => {
    if (disableJointDelivery) return true;
    else if (checkedJointDelivery.length) {
      if (checkedJointDelivery[0].addressId !== item.address.id) return true;
      else if (checkedJointDelivery[0].deliveryMethodId !== item.deliveryMethod?.id) return true;
    }

    return false;
  };

  return (
    <Checkbox
      checked={checked}
      disabled={isDisabled()}
      onChange={() => onChange(item)}
      label={translate("JOINT_DELIVERY")}
    />
  );
};

JointDeliveryCheckbox.propTypes = {
  item: PropType.object,
  checkedJointDelivery: PropType.array,
  onCheckJointDelivery: PropType.func,
  disableJointDelivery: PropType.bool,
};

export default JointDeliveryCheckbox;
