import { useHistory } from "@myloc/myloc-utils";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import useCancellablePromise from "./useCancellablePromise";

const usePagination = ({ request, loadOnMount = true, loading, skipSearch }) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const from = searchParams.get("from") || 0;
  const amount = searchParams.get("amount") || 24;

  const [pagination, setPagination] = useState({
    from,
    amount: amount,
    count: null,
  });
  const [data, setData] = useState([]);
  const [resetPage, doResetPage] = useState(true);
  const [page, setPage] = useState("");
  const { cancellablePromise } = useCancellablePromise({ triggerValue: request });
  const mounted = useRef(loadOnMount);

  useEffect(() => {
    if (mounted.current) {
      const params = new URLSearchParams(window.location.search);

      if (skipSearch) {
        setData([]);
        params.delete("from");
        params.delete("amount");
        return;
      }

      if (resetPage) {
        let _pagination = { ...pagination };
        _pagination.from = 0;
        setPagination(_pagination);
        setPage(1);
        params.delete("from");
        params.delete("amount");
      }

      if (loading) loading(true);

      cancellablePromise(
        request({
          from: pagination.from,
          amount: pagination.amount,
        }),
      ).then(response => {
        if (response.cancelled) return;
        response = response.value;
        if (response && !response.skipSearch) {
          if (response?.data?.page) {
            setData(response.data.page);
          } else if (response?.data) {
            setData(response.data);
          }
        }

        if (response && !response.skipSearch) {
          setPagination(pagination => ({ ...pagination, count: response.data.count ? response.data.count : 0 }));
        } else {
          setData([]);
          params.delete("from");
          params.delete("amount");
        }
        if (loading) loading(false);
      });
      doResetPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.from, pagination.amount, request]);

  useEffect(() => {
    mounted.current = true;
    if (pagination.from != 0) {
      handlePageChange(Math.ceil(pagination.from / pagination.amount) + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = toPage => {
    setPage(toPage);
    doResetPage(false);
    const params = new URLSearchParams(window.location.search);
    params.set("from", (toPage - 1) * pagination.amount);
    params.set("amount", searchParams.get("amount") || amount);
    history.push(window.location.pathname, params);

    window.scrollTo({ top: 0 });

    setPagination({ ...pagination, from: (toPage - 1) * pagination.amount, amount: pagination.amount });
  };

  const handlePageSizeChange = size => {
    if (pagination.count == null) return;

    const params = new URLSearchParams(window.location.search);
    params.set("from", 0);
    params.set("amount", size);
    history.push(window.location.pathname, params);

    setPagination({ ...pagination, from: 0, amount: size });
  };

  return {
    handlePageSizeChange,
    handlePageChange,
    data: data,
    settings: pagination,
    page: page,
    setPage: setPage,
  };
};

export default usePagination;
