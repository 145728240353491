import { URL } from "@myloc/myloc-utils";
import { matchPath } from "react-router";
import store from "../reducers/rootReducer";

const endpoint = (url, params = {}, version = "/fsp/v1") => {
  const content = new URLSearchParams(window.location.search);
  const state = store.getState();
  const root = process.env.REACT_APP_API_URL;

  const urlParams = matchPath(window.location.pathname, {
    path: "/orders/:order/:type/:content",
  });

  const clientParams = matchPath(window.location.pathname, {
    path: "/clients/:client",
  });

  const defaults = {
    order: urlParams?.params?.order || state?.appData?.order?.id,
    content: urlParams?.params?.content || content.get("contentid"),
    client: clientParams?.params?.client,
  };

  const templates = {
    ...defaults,
    ...params,
  };

  const preparedUrl = url.replace(/{([^}]+)}/g, (_, key) => templates[key]);

  const uri = new URL(`${root}${version}${preparedUrl}`);

  if (params.filter) {
    uri.addParameter("from", params.filter.from);
    uri.addParameter("amount", params.filter.amount);
  }

  return uri;
};

const hasContent = () => {
  const params = matchPath(window.location.pathname, {
    path: "/orders/:order/:type/:content",
  });

  const content = new URLSearchParams(window.location.search);

  return Boolean(content.get("contentid") || Boolean(params?.params?.content));
};

export const api = {
  account: {
    authentication: () => endpoint("/authentication"),
    confirmAccessMessage: () => endpoint("/user/message"),
    login: () => endpoint("/authentication"),
    logout: () => endpoint("/authentication"),
    appData: () => endpoint("/authentication"),
    settings: () => endpoint("/user"),
    location: () => endpoint("/user/location"),
    password: () => endpoint("/password"),
    passwordRules: () => endpoint("/password/rules"),
    reset: () => endpoint("/password"),
  },
  baseStorage: {
    baseStorageBalance: () => endpoint("/base-storages/balances"),
    inventoryAction: inventoryId => endpoint("/inventories/{inventory}", { inventory: inventoryId }),
    inventoryLines: lineId =>
      lineId ? endpoint("/inventories/lines/{line}", { line: lineId }) : endpoint("/inventories/lines"),
    scrap: () => endpoint("/base-storages/balances/scrap"),
    inventory: facilityId =>
      facilityId
        ? endpoint("/base-storages/{facility}/inventories", { facility: facilityId })
        : endpoint("/inventories"),
  },
  client: {
    client: clientId => endpoint("/clients/{client}", { client: clientId }),
    clients: () => endpoint("/clients"),
    address: ({ clientId, addressId }) => {
      return !addressId
        ? endpoint("/clients/{client}/addresses", { client: clientId })
        : endpoint("/clients/{client}/addresses/{address}", { client: clientId, address: addressId });
    },
    contact: ({ clientId, contactId }) => {
      return !contactId
        ? endpoint("/clients/{client}/contacts", { client: clientId })
        : endpoint("/clients/{client}/contacts/{contact}", { client: clientId, contact: contactId });
    },
    holding: ({ clientId, inventoryId, filter = null }) => {
      return !inventoryId
        ? endpoint("/clients/{client}/holding", { client: clientId, filter })
        : endpoint("/clients/{client}/holding/{inventory}", { client: clientId, inventory: inventoryId });
    },
    tasks: clientId => endpoint("/clients/{client}/tasks", { client: clientId }),
    orders: clientId => endpoint("/clients/{client}/web-orders", { client: clientId }),
    accessories: ({ clientId, holdingId }) =>
      endpoint("/clients/{client}/holding/{holding}/accessories", { client: clientId, holding: holdingId }),
  },
  customer: {
    customers: () => endpoint("/customers"),
  },
  deliveryMethod: {
    deliveryMethods: () => endpoint("/delivery-methods"),
  },
  deviation: {
    deviation: deviationId =>
      deviationId ? endpoint("/deviations/{deviation}", { deviation: deviationId }) : endpoint("/deviations"),
    categories: () => endpoint("/deviations/categories"),
    references: categoryId => endpoint("/deviations/categories/{category}/references", { category: categoryId }),
    files: deviationId => endpoint("/deviations/{deviation}/files", { deviation: deviationId }),
  },
  documents: {
    folders: () => endpoint("/company-guides/categories"),
    documents: () => endpoint("/company-guides"),
  },
  facility: {
    facility: facilityId => endpoint("/base-storages/{facility}", { facility: facilityId }),
    facilities: () => endpoint("/base-storages"),
  },
  files: fileId => (fileId ? endpoint("/files/{file}", { file: fileId }, "/v1") : endpoint("/files", {}, "/v1")),
  order: {
    orders: () => endpoint("/web-orders"),
    order: orderId => endpoint("/web-orders/{order}", { order: orderId }),
    category: () =>
      hasContent()
        ? endpoint("/web-orders/{order}/content/{content}/categories")
        : endpoint("/web-orders/functions/categories"),
    comments: () => endpoint("/web-orders/{order}/content/{content}/comments"),
    clientHoldings: (orderId, contentId) =>
      contentId
        ? endpoint("/web-orders/{order}/content/{content}/client-holdings", { order: orderId, content: contentId })
        : endpoint("/web-orders/{order}/client-holdings", { order: orderId }),
    filter: () => endpoint(`/web-orders/filters`),
    delivery: (orderId, contentId) =>
      contentId
        ? endpoint("/web-orders/{order}/content/{content}/delivery", { order: orderId, content: contentId })
        : endpoint("/web-orders/{order}/content/{content}/delivery"),
    cart: (orderId, contentId) =>
      endpoint("/web-orders/{order}/content/{content}", { order: orderId, content: contentId }),
    form: (orderId, contentId) =>
      endpoint("/web-orders/{order}/content/{content}/form", { order: orderId, content: contentId }),
    content: contentId => {
      if (contentId) {
        return endpoint("/web-orders/{order}/content/{content}", { content: contentId });
      }

      return hasContent() ? endpoint("/web-orders/{order}/content/{content}") : endpoint("/web-orders/{order}/content");
    },
    createContentWithParent: orderId => endpoint("/web-orders/{order}/content", { order: orderId }),
    make: () => endpoint("/web-orders/{order}/content/{content}/makes"),
    accessories: (orderId, contentId) =>
      endpoint("/web-orders/{order}/content/{content}/accessories", { order: orderId, content: contentId }),
    files: contentId => {
      return contentId
        ? endpoint("/web-orders/{order}/content/{content}/files", { content: contentId })
        : endpoint("/web-orders/{order}/content/{content}/files");
    },
    file: (fileId, contentId) => {
      return contentId
        ? endpoint("/web-orders/{order}/content/{content}/files/{file}", { file: fileId, content: contentId })
        : endpoint("/web-orders/{order}/content/{content}/files/{file}", { file: fileId });
    },
    deleteFile: (orderId, contentId, fileId) =>
      endpoint("/web-orders/{order}/content/{content}/files/{file}", {
        order: orderId,
        content: contentId,
        file: fileId,
      }),
    type: (orderId, contentId) =>
      endpoint("/web-orders/{order}/content/{content}/sub-content-types", { order: orderId, content: contentId }),
    line: contentId => endpoint("/web-orders/{order}/content/{content}/lines", { content: contentId }),
    send: orderId => endpoint("/web-orders/{order}", { order: orderId }),
  },
  pageContent: {
    content: () => endpoint("/page-content"),
  },
  product: {
    product: () => endpoint("/products"),
    suppliers: () => endpoint("/product-suppliers"),
    inventory: () => endpoint("/fixed-assets"),
    fixedAsset: ({ productId }) => endpoint("/fixed-assets/{product}", { product: productId }),
    label: ({ productId }) => endpoint("/fixed-assets/{product}/label", { product: productId }),
    logisticsOrder: ({ productId }) => endpoint("/fixed-assets/{product}/logistics-orders", { product: productId }),
    filters: () => endpoint("/products/filters"),
    productDetail: ({ productId }) => endpoint(`/products/{product}`, { product: productId }),
    pageContents: ({ productId }) => endpoint("/products/{product}/page-contents", { product: productId }),
    reasonCodes: () => endpoint("/reason-codes"),
  },

  receive: {
    deliveries: () => endpoint("/deliveries"),
  },

  resourceGroup: {
    resources: resourceGroupId => {
      return endpoint("/resource-groups/{resource}/resources", { resource: resourceGroupId });
    },
  },
  singleSignOn: {
    getUserInfo: () => {
      let port = window.location.port;

      if (port !== "80" && port !== "443" && port !== "") {
        port = ":" + port;
      }

      const url = window.location.protocol + "//" + window.location.hostname + port + "/MyL/redirect_uri?info=json";
      return new URL(url);
    },
  },
  webOrders: {
    content: ({ contentId }) => endpoint(`/web-orders/{order}/content/{content}`, { content: contentId }),
    fittingTypes: () => endpoint(`/web-orders/fittings/types`),
    meetings: meetingId =>
      meetingId
        ? endpoint(`/web-orders/fittings/meetings/{meeting}`, { meeting: meetingId })
        : endpoint(`/web-orders/fittings/meetings`),
    fittings: fittingId =>
      fittingId ? endpoint(`/web-orders/fittings/{fitting}`, { fitting: fittingId }) : endpoint(`/web-orders/fittings`),
    contentFiles: (orderId, contentId) =>
      endpoint("/web-orders/{order}/content/{content}/files", { order: orderId, content: contentId }),
    files: fittingId => endpoint("/web-orders/fittings/{fitting}/files", { fitting: fittingId }),
    fittingMeeting: (fittingId, meetingId) =>
      meetingId
        ? endpoint(`/web-orders/fittings/{fitting}/meetings/{meeting}`, { fitting: fittingId, meeting: meetingId })
        : endpoint(`/web-orders/fittings/{fitting}/meetings`, { fitting: fittingId }),
    deleteFile: (fittingId, fileId) =>
      endpoint("/web-orders/fittings/{fitting}/files/{file}", { fitting: fittingId, file: fileId }),
    fittingOrders: fittingId => endpoint("/web-orders/fittings/{fitting}/content", { fitting: fittingId }),
  },
  tasks: task => (task ? endpoint(`/tasks/{task}`, { task }) : endpoint("/tasks")),
};

const allowRedirectionToAfterLogin = desiredPage => {
  //Not allowed pages to restrict redirection to after login - only part of url is needed
  const PASSWORD = "/password";

  const DISALLOWED_PARTS_OF_PATH = [PASSWORD];

  //If returned value from findIndex >= 0, a disallowed part of path has been found among the DISALLOWED urls
  const posInDisallowedArray = DISALLOWED_PARTS_OF_PATH.findIndex(element => {
    return desiredPage.indexOf(element) >= 0;
  });

  //Return allowed (true) if no positsion in array was found (-1)
  return posInDisallowedArray === -1;
};

export const myloc = {
  webpageUrl: new URL("https://myloc.se"),
  cookieIntegrityUrl: new URL("https://myloc.se/sv/gdpr/"),
  contactEmail: new URL("info@myloc.se"),
  contactPhone: new URL("040-6304643"),
  supportEmail: new URL("support.logistics@myloc.se"),
  address: {
    street: "Kärleksgatan 1A",
    postalNo: "211 45",
    city: "Malmö",
    county: "Skåne",
  },
};

export const pages = {
  allowRedirectionToAfterLogin,
};
