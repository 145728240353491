import PropType from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import { ContentType, Item, ItemInfo, Row, RowTitle } from "../ListComponents/ListComponents";

function Work({ list, onDelete, readOnly, isReferencedOrder }) {
  const translate = useTranslate();
  const isEmbedded = Boolean(window.location.pathname.match(/^\/embedded\//));

  return (
    <ContentType title={translate("CART_WORK_TITLE")}>
      {list.map(item => (
        <Item
          key={item.id}
          orderData={item}
          onDelete={onDelete}
          readOnly={readOnly}
          addressTitle={translate("WORK_ORDER_ADDRESS_TITLE")}
          isEmbedded={isEmbedded}
          isReferencedOrder={isReferencedOrder}
        >
          <ItemInfo grid={false}>
            <Row title={translate("WORK_TYPE")} text={item.subContentType.value} margin />
            <RowTitle text={item.product?.name} />
            <Row title={translate("INVENTORY_NUMBER_SHORT")} text={item.product?.inventoryNumber} />
            <Row title={translate("PRODUCT_NUMBER_SHORT")} text={item.product?.productNumber} margin />
            {item.deliveryDate && <Row title={translate("ETA")} text={item.deliveryDate} />}
            {item.text && <Row title={translate("DESCRIBE_AID")} text={item.text} />}
          </ItemInfo>
        </Item>
      ))}
    </ContentType>
  );
}

Work.propTypes = {
  list: PropType.arrayOf(
    PropType.shape({
      contentType: PropType.string,
      product: PropType.shape({
        name: PropType.string,
        inventoryNumber: PropType.string,
        productNumber: PropType.string,
      }),
      id: PropType.string,
      subContentType: PropType.shape({
        value: PropType.string,
      }),
      text: PropType.string,
    }),
  ),
  onDelete: PropType.func,
  readOnly: PropType.bool,
  isReferencedOrder: PropType.func,
};

export default Work;
