import PropType from "prop-types";
import { useTranslate } from "../../../language/i18n";
import ProductImage, { ImagePreset } from "../../Products/ProductImage/ProductImage";
import AccordionItem from "../AccordionItem/AccordionItem";
import Label from "../Label/Label";
import styles from "./InventoryAccessories.module.scss";

const InventoryAccessories = ({ accessories }) => {
  const translate = useTranslate();

  return (
    <AccordionItem title={translate("ACCESSORIES")}>
      {accessories?.map(accessory => (
        <div key={accessory.id} className={styles.accessories}>
          <div className={styles.accessory}>
            <div className={styles.image}>
              <ProductImage images={accessory.images} preset={ImagePreset.ICON} className={styles.productImage} />
            </div>
            <div className={styles.details}>
              <div className={styles.head}>
                <div className={styles.left}>{accessory.description}</div>
                <div className={styles.right}>
                  {accessory.quantity} {accessory.unit}
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>
                  <div className={styles.row}>
                    <Label inline label={translate("PRODUCT_NUMBER_SHORT")} value={accessory?.productNumber} />
                  </div>

                  <div className={styles.row}>
                    <Label inline label={translate("ARTICLE_CLASS")} value={accessory.itemClass?.label} hideEmpty />
                  </div>
                </div>

                <div className={styles.right}>
                  <div className={styles.row}>
                    <Label
                      inline
                      label={translate("INVENTORY_NUMBER_SHORT")}
                      value={accessory?.inventoryNumber}
                      hideEmpty
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </AccordionItem>
  );
};

InventoryAccessories.propTypes = {
  accessories: PropType.array.isRequired,
};
export default InventoryAccessories;
