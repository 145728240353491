import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getCustomers() {
  const url = api.customer.customers();
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default { getCustomers };
